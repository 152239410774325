import { WarningLevelView } from './View';

export const warningLevelConfig = {
  id: 'warningLevel',
  title: 'Waarschuwingsniveau',
  view: WarningLevelView,
  edit: WarningLevelView,
  restricted: true,
  group: 'common',
  mostUsed: false,
};
