/* eslint-disable prettier/prettier */
import { H2, Label, Text } from '../../Typography';
import { socials } from './socials';
import { Input } from '../../Inputs';
import { Button } from '../../Button';
import { useBreakpoint } from '../../../helpers/useMedia';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  FollowVMM: {
    id: 'FollowVMM',
    defaultMessage: 'Follow VMM on social media', // Volg VMM op sociale media
  },
  StayUpToDate: {
    id: 'StayUpToDate',
    defaultMessage: 'Do you like to stay informed?', // Blijf je graag op de hoogte?
  },
  MonthlyNews: {
    id: 'MonthlyNews',
    defaultMessage:
      'Each month, we curate for you a selection of the most important news items tailored to the environmental professional.',
    // We maken maandelijks voor jou een selectie van de belangrijkste nieuwsberichten op maat van de milieuprofessional.
  },
  SubscribeToNewsletter: {
    id: 'SubscribeToNewsletter',
    defaultMessage: 'Subscribe to the newsletter', // Schrijf je in op de nieuwsbrief
  },
  Email: {
    id: 'Email',
    defaultMessage: 'E-mail', // E-mail
  },
  Subscribe: {
    id: 'Subscribe',
    defaultMessage: 'Subscribe', // Inschrijven
  },
});

export const SocialsView = () => {
  const intl = useIntl();
  const isBigScreen = useBreakpoint('lg');

  const socialsElements = (
    <div className="socials-block__links">
      <Text size="xs" weight="500" uppercase>
        {intl.formatMessage(messages.FollowVMM)}
      </Text>
      <div>
        {socials.map(([src, href, size]) => (
          <a href={href} key={src}>
            <img
              src={src}
              style={{
                width: size,
                height: size,
              }}
              alt="Social logo"
            />
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div className="block socials-block">
      <div className="container">
        <div>
          <H2>{intl.formatMessage(messages.StayUpToDate)}</H2>
          <Text size="lg">{intl.formatMessage(messages.MonthlyNews)}</Text>

          {isBigScreen && socialsElements}
        </div>
        <div>
          <Text
            size="xs"
            weight="500"
            uppercase
            className="socials-block__news-letter-title"
          >
            {intl.formatMessage(messages.SubscribeToNewsletter)}
          </Text>
          <form className="socials-block__news-letter-form">
            <Label htmlFor="#news-letter-input">
              {intl.formatMessage(messages.Email)}
              <Input type="email" />
            </Label>
            <Button halfRounded size="large">
              {intl.formatMessage(messages.Subscribe)}
            </Button>
          </form>
          {!isBigScreen && socialsElements}
        </div>
      </div>
    </div>
  );
};
