import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { H5 } from '../../Typography';
import { defineMessages, useIntl } from 'react-intl';
import { format, getDate, getMonth } from 'date-fns';

type AgendaProps = {
  content: {
    title: string;
    description: string;
    preview_image: {
      download: string;
    };
    preview_caption: string;
    location: string;
    contact_name: string;
    contact_email: string;
    contact_phone: string;
    event_url: string;
    start: string;
    end: string;
  };
};

// Define translatable messages
const messages = defineMessages({
  PracticalInfo: {
    id: 'PracticalInfo',
    defaultMessage: 'Practical information',
  },
  Date: {
    id: 'Date',
    defaultMessage: 'Date',
  },
  Location: {
    id: 'Location',
    defaultMessage: 'Location',
  },
  ContactName: {
    id: 'ContactName',
    defaultMessage: 'Contact name',
  },
  ContactMail: {
    id: 'ContactMail',
    defaultMessage: 'Contact e-mail',
  },
  ContactPhone: {
    id: 'ContactPhone',
    defaultMessage: 'Contact phone',
  },
  EventURL: {
    id: 'EventURL',
    defaultMessage: 'Event URL',
  },
});

const EventDate = ({ end, start }: { end: string; start: string }) => {
  const endDate = new Date(end);
  const startDate = new Date(start);
  if (getMonth(endDate) !== getMonth(startDate))
    return (
      <>
        <b>{format(startDate, 'd')}</b> {format(startDate, 'MMMM yyyy')}
        {' - '}
        <b>{format(endDate, 'd')}</b> {format(endDate, 'MMMM yyyy')}
      </>
    );

  if (getDate(endDate) !== getDate(startDate))
    return (
      <>
        <b>
          {format(startDate, 'd')} - {format(endDate, 'd')}
        </b>{' '}
        {format(startDate, 'MMMM yyyy')}
      </>
    );

  return (
    <>
      <b>{format(startDate, 'd')}</b> {format(startDate, 'MMMM yyyy')}
    </>
  );
};

const Agenda = ({ content }: AgendaProps) => {
  const intl = useIntl();

  var urlhost = '';
  try {
    var eventurl = new URL(content?.event_url);
    urlhost = eventurl?.hostname;
  } catch (e) {}

  return (
    <div id="page-document" className="agenda">
      <div className="agenda-flex">
        <div className="agenda-left">
          <RenderBlocks content={content} />
        </div>
        <div className="agenda-right">
          <div className="right-side-section">
            <H5>{intl.formatMessage(messages.PracticalInfo)}</H5>
            <div className="practical-info">
              <p className="infoTitle">{intl.formatMessage(messages.Date)}</p>
              <p className="infoContent">
                <EventDate start={content?.start} end={content?.end} />
              </p>
            </div>
            <div className="practical-info">
              <p className="infoTitle">
                {intl.formatMessage(messages.Location)}
              </p>
              <p className="infoContent">{content?.location}</p>
            </div>

            {content?.contact_name && (
              <div className="practical-info">
                <p className="infoTitle">
                  {intl.formatMessage(messages.ContactName)}
                </p>
                <p className="infoContent">{content?.contact_name}</p>
              </div>
            )}

            {content?.contact_email && (
              <div className="practical-info">
                <p className="infoTitle">
                  {intl.formatMessage(messages.ContactMail)}
                </p>
                <p className="infoContent">{content?.contact_email}</p>
              </div>
            )}

            {content?.contact_phone && (
              <div className="practical-info">
                <p className="infoTitle">
                  {intl.formatMessage(messages.ContactPhone)}
                </p>
                <p className="infoContent">{content?.contact_phone}</p>
              </div>
            )}

            {content?.event_url && (
              <div className="practical-info">
                <p className="infoTitle">
                  {intl.formatMessage(messages.EventURL)}
                </p>
                {urlhost.length > 0 ? (
                  <p className="infoContent">
                    <UniversalLink
                      openLinkInNewTab={true}
                      href={content?.event_url}
                    >
                      {urlhost}
                    </UniversalLink>
                  </p>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
