import { Indicator as IndicatorType } from '../Blocks/IndicatorHeader/indicators';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

type Props = {
  indicator: IndicatorType;
  vertical?: boolean;
  dark?: boolean;
};

// Define translatable messages
const messages = defineMessages({
  Tomorrow: {
    id: 'Tomorrow',
    defaultMessage: 'Morgen:', // Morgen:
  },
});

export const Indicator = ({ dark, indicator, vertical }: Props) => {
  const intl = useIntl();

  const classNames = cx('indicator', {
    'indicator--vertical': vertical,
    'indicator--dark': dark,
  });

  return (
    <div className={classNames}>
      <p className="indicator__name">{indicator.name}</p>
      <div className="indicator__content">
        <img
          className={['indicator__icon', !dark && 'indicator__icon--light']
            .filter(Boolean)
            .join(' ')}
          alt={indicator.name}
          src={indicator.icon || undefined}
        />
        <div>
          <p className="indicator__status">
            <span
              className="indicator__status-color"
              style={{ background: indicator.color || 'rgba(0, 0, 0, .3)' }}
            />
            {indicator.status || 'Geen status'}
          </p>
          {indicator.futureStatus && (
            <p className="indicator__future-status">
              <span>{intl.formatMessage(messages.Tomorrow)}</span>{' '}
              {indicator.futureStatus}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
