import PropTypes from 'prop-types';
import { ConditionalLink, Image } from '@plone/volto/components';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Tags } from '../../Tag/Tags';
import { H3, Text } from '../../Typography';
import { format, getDate, getMonth } from 'date-fns';

type ListingItem = RelatedItem & { tags: string[]; end: string; start: string };
type Props = BlockViewProps & {
  '@type': string;
  item: ListingItem;
  blocks_layout: { items: any[] };
  styles?: { gridCutoff: 2 | 3 | 4 };
  isEditMode: boolean;
};

const EventDate = ({ end, start }: { end: string; start: string }) => {
  const endDate = new Date(end);
  const startDate = new Date(start);
  if (getMonth(endDate) !== getMonth(startDate))
    return (
      <>
        <b>{format(startDate, 'd')}</b> {format(startDate, 'MMMM yyyy')}
        {' - '}
        <b>{format(endDate, 'd')}</b> {format(endDate, 'MMMM yyyy')}
      </>
    );

  if (getDate(endDate) !== getDate(startDate))
    return (
      <>
        <b>
          {format(startDate, 'd')} - {format(endDate, 'd')}
        </b>{' '}
        {format(startDate, 'MMMM yyyy')}
      </>
    );

  return (
    <>
      <b>{format(startDate, 'd')}</b> {format(startDate, 'MMMM yyyy')}
    </>
  );
};

const EventsItem = ({ item, isEditMode }: Props) => {
  return (
    <div className="listing-item listing-item--event" key={item['@id']}>
      <ConditionalLink to={null} item={item} condition={!isEditMode}>
        <div className="listing-body">
          <Image
            item={item}
            imageField={item.image_field}
            alt=""
            loading="lazy"
            responsive={true}
          />
          <div className="listing-body__content">
            <Text className="listing-body__date">
              <EventDate start={item.start} end={item.end} />
            </Text>
            <H3>{item.title ? item.title : item['@id']}</H3>
            <Text>{item.description}</Text>
            <Tags tags={item.tags} />
          </div>
        </div>
      </ConditionalLink>
    </div>
  );
};
EventsItem.propTypes = {
  item: PropTypes.any.isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default EventsItem;
