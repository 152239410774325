import DRAUGHT from '../../../assets/indicators/draught.svg';
import FLOOD from '../../../assets/indicators/flood.svg';
import AIR_QUALITY from '../../../assets/indicators/air_quality.svg';
import RAINFALL from '../../../assets/indicators/rainfall.svg';
import TIDE from '../../../assets/indicators/tide.svg';

import config from '@plone/volto/registry';

/*import apiResponse from './api-response-example.json'; */

export type IndicatorStatus =
  | 'Zeer goed'
  | 'Goed'
  | 'Geen risico'
  | 'Normaal'
  | 'Verhoogd risico'
  | 'Slecht';
export type Indicator = {
  id: string;
  name: string;
  icon: string | null;
  status: IndicatorStatus;
  futureStatus: IndicatorStatus | null;
  color: string;
  href: string;
};

const map: {
  [name: string]: {
    name: string;
    icon: string | null;
  };
} = {
  lucht: {
    name: 'Luchtkwaliteit',
    icon: AIR_QUALITY,
  },
  overstroming: { name: 'Overstromingen', icon: FLOOD },
  neerslag: { name: 'Neerslag', icon: RAINFALL },
  droogte: { name: 'Droogte', icon: DRAUGHT },
  getij: { name: 'Getij', icon: TIDE },
};
export const indicators = Object.keys(map);

export const getIndicators = async () => {
  if ((config.settings.headerIndicatorUrl.length = 0)) {
    return indicators.map((id) => ({
      id,
      name: map[id]?.name || id,
      icon: map[id]?.icon,
      status: 'geen actuele status' as IndicatorStatus,
      futureStatus: null,
      color: '#CCCCCC',
      href: '#',
    }));
  }
  return fetch(config.settings.HeaderIndicatorApiUrl)
    .then((r) => r.json() as Promise<typeof apiResponse>)
    .then((r) =>
      r.value.reduce((list, v, _, arr) => {
        if (v.naam.indexOf('actueel') === -1) return list;

        const name = v.naam.replace('-actueel', '');
        const indicator: Indicator = {
          id: name,
          name: map[name]?.name || name,
          icon: map[name]?.icon,
          status: v.status_beschrijving as IndicatorStatus,
          futureStatus:
            (arr.find((v) => v.naam === `${name}-morgen`)
              ?.status_beschrijving as IndicatorStatus) || null,
          color: v.kleur,
          href: '#',
        };

        list.push(indicator);

        return list;
      }, [] as Indicator[]),
    )
    .catch(() => {
      return indicators.map((id) => ({
        id,
        name: map[id]?.name || id,
        icon: map[id]?.icon,
        status: 'geen actuele status' as IndicatorStatus,
        futureStatus: null,
        color: '#CCCCCC',
        href: '#',
      }));
    });
};

// export const indicators: Indicator[] = [
//   {
//     name: 'Lucht',
//     status: 'Goed',
//     color: '#00E789',
//     futureStatus: 'Zeer goed',
//     icon: AIR_QUALITY,
//     iconDark: AIR_QUALITY_DARK,
//     href: '#',
//   },
//   {
//     name: 'Overstroming',
//     status: 'Geen risico',
//     color: '#00E789',
//     futureStatus: 'Verhoogd risico',
//     icon: FLOOD,
//     iconDark: FLOOD_DARK,
//     href: '#',
//   },
//   {
//     name: 'Droogte',
//     status: 'Normaal',
//     color: '#20E5F1',
//     futureStatus: 'Normaal',
//     icon: DRAUGHT,
//     iconDark: DRAUGHT_DARK,
//     href: '#',
//   },
// ];
