/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable prettier/prettier */
import { Container } from '@plone/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  Questions: {
    id: 'Questions',
    defaultMessage: 'Do you have questions?', // Heb je vragen?
  },
  FaqOverview: {
    id: 'FaqOverview',
    defaultMessage: 'See the overview of', // Bekijk verzicht van
  },
  FaqLink: {
    id: 'FaqLink',
    defaultMessage: 'frequently asked questions', // meestgestelde vragen
  },
  NotFound: {
    id: 'NotFound',
    defaultMessage: "Didn't find what you were looking for?", // Niet gevonden wat je zocht?
  },
  ContactForm: {
    id: 'ContactForm',
    defaultMessage: 'Fill out our contact form', // Vul ons contactformulier in
  },
  Call1700: {
    id: 'Call1700',
    defaultMessage: 'Call 1700 for free.', // Bel gratis 1700.
  },
});

export const SupportFooter = () => {
  const intl = useIntl();

  return (
    <div className="support-footer footer">
      <Container layout className="support-footer__container">
        <h2>{intl.formatMessage(messages.Questions)}</h2>
        <ul>
          <li>
            {intl.formatMessage(messages.FaqOverview)}{' '}
            <a href="#">{intl.formatMessage(messages.FaqLink)}</a>.
          </li>
          <li>
            {intl.formatMessage(messages.NotFound)}{' '}
            <a href="#">{intl.formatMessage(messages.ContactForm)}</a>.
          </li>
          <li>
            <a href="tel:1700">{intl.formatMessage(messages.Call1700)}</a>
          </li>
        </ul>
      </Container>
    </div>
  );
};
