import type { BlockViewProps } from '@plone/types';
import { getWarningIcon } from '../../Warnings';
import { defineMessages, useIntl } from 'react-intl';
import { Text } from '../../Typography';
import { Card } from '../../Card';

// Define translatable messages
const messages = defineMessages({
  warnings: {
    id: 'WarningLevel',
    defaultMessage: 'Warninglevel:', // Waarschuwingensniveau:
  },
});

export const WarningLevelView = (
  props: BlockViewProps & {
    content: { warninglevel: 'red' | 'yellow' | 'white' };
  },
) => {
  const intl = useIntl();

  if (!props.content.warninglevel) {
    return null;
  }

  const icon = getWarningIcon(props.content.warninglevel);
  return (
    <Card dark>
      <Text color="light">
        {intl.formatMessage(messages.warnings)}{' '}
        <img alt="warning level" src={icon} />
      </Text>
    </Card>
  );
};
