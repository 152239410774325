import { useRef, ComponentType } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Pagination, Dimmer, Loader } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import withQuerystringResults from '@plone/volto/components/manage/Blocks/Listing/withQuerystringResults';
import type {
  BlockConfigBase,
  BlockExtension,
  BlockViewProps,
  RelatedItem,
} from '@plone/types';
import { H2, Link } from '../../Typography';

type Variation = BlockExtension & {
  isDefault: boolean;
  noResultsComponent: ComponentType<{ isEditMode: boolean }>;
};
type Props = BlockViewProps & {
  isEditMode: boolean;
  listingItems: RelatedItem[];
  totalPages: number;
  onPaginationChange: (
    mouseEvent: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    props: { activePage: number | string | undefined },
  ) => void;
  variation: Variation;
  currentPage: number;
  batch_size: number;
  prevBatch: number;
  nextBatch: number;
  isFolderContentsListing: boolean;
  hasLoaded: boolean;
  id: string;
  total: number;
  data: {
    template: string;
    headlineTag: string;
    headline: string;
    headlineLink: [RelatedItem];
    headlineLinkLabel: string;
    variation: string;
  };
};
const ListingBody = withQuerystringResults((props: Props) => {
  const {
    data,
    isEditMode,
    listingItems,
    totalPages,
    onPaginationChange,
    variation,
    currentPage,
    batch_size,
    prevBatch,
    nextBatch,
    isFolderContentsListing,
    hasLoaded,
    total,
    properties,
  } = props;

  let ListingBodyTemplate:
    | undefined
    | null
    | ComponentType<{ items: RelatedItem[]; isEditMode: boolean }>;
  // Legacy support if template is present
  const variations = (config.blocks?.blocksConfig['listing']?.variations ||
    []) as Variation[];
  const defaultVariation = variations.filter((item) => item.isDefault)?.[0];

  if (data.template && !data.variation) {
    const legacyTemplateConfig = variations.find(
      (item) => item.id === data.template,
    );
    ListingBodyTemplate = legacyTemplateConfig?.template;
  } else {
    ListingBodyTemplate =
      variation?.template ?? defaultVariation?.template ?? null;
  }
  const listingRef = useRef<null | HTMLDivElement>(null);

  const NoResults = variation?.noResultsComponent
    ? variation.noResultsComponent
    : (
        config.blocks?.blocksConfig['listing'] as BlockConfigBase & {
          noResultsComponent: ComponentType;
        }
      ).noResultsComponent;

  return (
    <>
      <div className="block-header">
        {data.headline && <H2 className="headline">{data.headline}</H2>}
        {data.headlineLink &&
          data.headlineLink !== '' &&
          data.headlineLink?.[0]?.title &&
          data.headlineLink?.[0]?.title !== '' && (
            <Link
              openLinkInNewTab={false}
              href={null}
              item={data?.headline?.[0]}
              size="sm"
            >
              {data.headlineLinkLabel || data.headlineLink?.[0]?.title}
              {' >'}
            </Link>
          )}
      </div>
      {listingItems?.length > 0 ? (
        <div ref={listingRef}>
          {ListingBodyTemplate && (
            <ListingBodyTemplate
              items={listingItems}
              isEditMode={isEditMode}
              {...data}
              {...variation}
            />
          )}
          {totalPages > 1 && (
            <div className="pagination-wrapper">
              <Pagination
                className="desktop-pagination"
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => {
                  !isEditMode &&
                    listingRef.current?.scrollIntoView({ behavior: 'smooth' });
                  onPaginationChange(e, { activePage });
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: (
                    <FormattedMessage
                      id="Previous Page"
                      defaultMessage="Previous Page"
                    />
                  ),
                  icon: false,
                  'aria-disabled': !prevBatch,
                  className: !prevBatch ? 'disabled' : null,
                }}
                nextItem={{
                  content: (
                    <FormattedMessage
                      id="Next Page"
                      defaultMessage="Next Page"
                    />
                  ),
                  icon: false,
                  'aria-disabled': !nextBatch,
                  className: !nextBatch ? 'disabled' : null,
                }}
              />
              <Pagination
                className="mobile-pagination"
                activePage={currentPage}
                totalPages={totalPages}
                boundaryRange={1}
                siblingRange={0}
                onPageChange={(e, { activePage }) => {
                  !isEditMode &&
                    listingRef.current?.scrollIntoView({ behavior: 'smooth' });
                  onPaginationChange(e, { activePage });
                }}
                firstItem={null}
                lastItem={null}
                prevItem={undefined}
                nextItem={undefined}
              />
              <div className="total">
                <FormattedMessage id="Result" defaultMessage="Result" />{' '}
                {(currentPage - 1) * batch_size + 1}-
                {(currentPage - 1) * batch_size + listingItems.length}{' '}
                <FormattedMessage id="of" defaultMessage="of" />{' '}
                {total || properties.items_total}
              </div>
            </div>
          )}
        </div>
      ) : isEditMode ? (
        <div className="listing message" ref={listingRef}>
          {isFolderContentsListing && (
            <FormattedMessage
              id="No items found in this container."
              defaultMessage="No items found in this container."
            />
          )}
          {hasLoaded && NoResults && (
            <NoResults isEditMode={isEditMode} {...data} />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      ) : (
        <div className="emptyListing">
          {hasLoaded && NoResults && (
            <NoResults isEditMode={isEditMode} {...data} />
          )}
          <Dimmer active={!hasLoaded} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
        </div>
      )}
    </>
  );
});

export default injectIntl(ListingBody);
